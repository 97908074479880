import '../scss/base.scss';
import slick from "@accessible360/accessible-slick"
import AOS from 'aos';
import "bootstrap";
import { Button } from 'bootstrap';
const {
  __,
  _x
} = wp.i18n;

__('__', 'fortytwo');
_x('_x', '_x_context', 'fortytwo');


$(document).ready(function () {


  function cleanScrollNav() {
    $('.scrollspy-container').removeAttr("tabindex");
  }

  function cleanAblePlayer() {
    $('.able-button-handler-fullscreen > span')
    $('.able-button-handler-fullscreen > span').each(function( index ) {
      $(this)[0].innerHTML = _x("Plein écran", 'fortytwo')
    });
  }


  function addTitleToIframe() {
    if ($(".carousel-testinomials").length > 0) { 
     const videoSlides = $(".carousel-testinomials").find(".slick-slide") 
    
    videoSlides.each(function(id, item) {
      if ($(this).hasClass('slick-cloned')){
        return;
      }

      else {
        let h3 = $(this).find("h3 span:first-child");
        let h3Title = h3.text();
        let timestamp = $(this).find("h3 span:last-child");
        let timestampTitle = timestamp.text();
        let iframeTitle = "Vidéo" + " " + h3Title + " " + timestampTitle
        let iframe = $(this).find("iframe");  
        iframe.attr('title', iframeTitle);
      }
      
    })
    
    }
  }
  
  window.setTimeout(function() {addTitleToIframe()}, 3000);

  $(".anchor--link").on("click", function(e){
     const elmtofocus = $(this)[0].getAttribute("href");
     if ($(elmtofocus).find("h3").length > 0) {
        elmtofocus.setAttribute("tabindex", "-1");
      return $(elmtofocus).find("h3").focus();
     }
      if ($(elmtofocus).find("h2").length > 0) {
            elmtofocus.setAttribute("tabindex", "-1");
      return $(elmtofocus).find("h2").focus();
     }
   });

  window.setTimeout(cleanScrollNav, 2000);
  window.setTimeout(cleanAblePlayer, 3000);

  function cleanIframe() {
    var iFrameElements = window.document.getElementsByTagName("iframe");
    for (var i = 0; i < iFrameElements.length; i++) {
      iFrameElements[i].removeAttribute("frameBorder"); //  For other browsers (just a backup for the above).
    }
  }

  window.setTimeout(cleanIframe, 2000);


  if ( window.innerWidth <= 1024) {
    var toggle   = '[data-bs-toggle=dropdown]'
    var menus = $(toggle).parent().find('ul').attr('role','menu')
    $(toggle).parent().on('click',function(e){
        let $par = $(this);
        let firstItem = $('.dropdown-menu .go--back', $par)[0]
        if (document.activeElement === firstItem) {
          this.children[0].style.display = 'block';
          this.children[0].focus();
          $(this).siblings().each(function(index, elm){
          elm.style.display = 'block';
        })
          return firstItem.blur();
        }
        firstItem.focus();
        this.children[0].style.display = 'none';
        $(this).siblings().each(function(index, elm){
          elm.style.display = 'none';
        })
        // var $toggle = $par.find(toggle);
        // console.log('button', $toggle);
        // $toggle.attr('aria-expanded','true');
        // console.log('firstitem', firstItem);
        // firstItem.focus();
      })
    // add menuitem role and tabIndex to dropdown links
    // lis.find('a').attr({'role':'menuitem', 'tabIndex':'-1'})
    // add aria attributes to dropdown toggle

    // $(toggle).parent()
    //   // Update aria-expanded when open
    //   .on('click',function(e){
    //     $par = $(this);
    //     firstItem = $('.dropdown-menu [role=menuitem]:visible', $par)[0]
    //     var $toggle = $par.find(toggle);
    //     console.log('button', $toggle);
    //     $toggle.attr('aria-expanded','true');
    //     console.log('firstitem', firstItem);
    //     firstItem.focus();
    //   })

    // let goBack = $('.dropdown-menu [role=menuitem]:visible', $par)[0]
    // if (goBack != null) {
    //   goBack.on('click',function(e){ 
    //     console.log('working!');
    //   })
    // }
      
  }
  



  // $('.main-navbar').on('click', function() {
  //   const allLi = $('.navbar-nav').find('li');
  //   allLi.each(function() {
  //      if ($( this ).hasClass('show')) {
  //       $('.navbar-nav').css("backgroundColor", "white");
  //       $('.navbar-global').css("backgroundColor", "white");
  //      }
  //   });
  // })
  
  $('button.nav-link').on('click', function() {
    $('.navbar-global').addClass('nav--white');
  })

  $(document).on('click', function () {
    if(!$(event.target).is('button.nav-link'))
    {
      $('.navbar-global').removeClass('nav--white');
    } 
  })

  const gobackWording = __('Menu', 'fortytwo');

  $(document).find('li.previous-menu-btn').each(function () {
    $(this).children().replaceWith($("<button class='go--back' type='button'>" + gobackWording + "</button>"));
  });

  $(".dropdown-menu").click(function () {
    $('.navbar-nav > li').each(function (i) {
      $(this).css("visibility", "visible").css('height', 'auto');
    });
  });

  // Get a city campus 

  $("#country-cities").change(function () {
    let postId = ($(this).val());
    $("#show-city-info").empty();
    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_get_city.ajaxurl,
      data: {
        'action': 'find_city_campus_ajax',
        'post_id': postId
      },
      success: function (data) {
        var $data = $(data);
        if ($data.length) {
          $("#show-city-info").append($data);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown)
        $("#show-city-info").html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      }

    });
    return false;
  });

  // CAMPUS
  $("#country-dropdown").change(function () {
    let termId = ($(this).val());
    $('#country-cities').find('option').not(':first').remove();
    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_cities.ajaxurl,
      data: {
        'action': 'hey_cities_ajax',
        'term_id': termId
      },
      success: function (data) {
        var $data = $(data);
        if ($data.length) {
          $("#country-cities").append($data);
          $("#country-cities").append($data).prop('disabled', false);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown)
        $("#country-cities").html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      }

    });
    return false;
  });

  // COUNTRY
  $("#inputGroupSelect02").change(function () {
    let termId = ($(this).val());
    $('#country-dropdown').find('option').not(':first').remove();
    $('#country-cities').find('option').not(':first').remove();
    $("#show-city-info").empty();
    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_taxonomy.ajaxurl,
      data: {
        'action': 'show_countries_ajax',
        'term_id': termId
      },
      success: function (data) {
        var $data = $(data);
        if ($data.length) {
          $("#country-dropdown").append($data).prop('disabled', false);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown)
        $("#country-dropdown").html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      }

    });
    return false;
  });

  $("#more_posts").on("click", function () { // When btn is pressed.
    $("#more_posts").attr("disabled", true); // Disable the button, temp.
    load_posts();
    $('#button-position').append(this)
  });

  var ppp = 3; // Post per page
  var pageNumber = 1;


  function load_posts() {
    pageNumber++;
    var str = '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_post_ajax';
    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_posts.ajaxurl,
      data: str,
      success: function (data) {
        var $data = $(data);
        if ($data.length) {
          $("#ajax-posts").append($data);
          $("#more_posts").attr("disabled", false);
        } else {
          $("#more_posts").attr("disabled", true);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      }

    });
    return false;
  }

  $("#more_posts").on("click", function () { // When btn is pressed.
    $("#more_posts").attr("disabled", true); // Disable the button, temp.
    load_posts();
    $('#button-position').append(this)
  });

  // Cache selectors
  var lastId,
    topMenu = $("#scrollspy"),
    topMenuHeight = topMenu.outerHeight() + 15,
    // All list items
    menuItems = topMenu.find("a"),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));
      if (item.length) {
        return item;
      }
    });

  // Bind click handler to menu items
  // so we can get a fancy scroll animation
  menuItems.click(function (e) {
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 300);
    e.preventDefault();
  });

  // Bind to scroll
  $(window).scroll(function () {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop)
        return this;
    });
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
        .parent().removeClass("active")
        .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
  });


  AOS.init({
    delay: 0,
    mirror: false,
    once: true,
    throttleDelay: 20
  });

  var $nav = $(".navbar");
  $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());


  $(function () {
    $(document).scroll(function () {
      var $nav = $(".navbar");
      $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });
  });

  var $navbar = $('.navbar-global');


  $('.menu-burger').click(function () {
    openModal($navbar);
    // $('.close--btn--menu > .close').focus();
    $('#main').css("display" , "none");
    $('.footer').css("display" , "none");
    $('.navbar-nav').toggleClass('display--grid');
    $('.navbar-global').toggleClass('mobile--menu');
    $('body').toggleClass('noscroll');
    $('.e--link').toggleClass('d-flex').toggleClass('d-none');
    $(this).addClass('d-none').removeClass('d-block');
    $('.close--btn--menu').addClass('d-block')
    $('.close--btn--menu').removeClass('d-none')
    $('.top-nav').removeClass('d-none')
    $('.insert').insertAfter('.order-1024-3');
    $('.close--menu-nav').focus();
  });

  $('.close--btn--menu').click(function () {
    closeModal($navbar);
    $('#main').css("display" , "block");
    $('.footer').css("display" , "block");
    $('.navbar-nav').toggleClass('display--grid');
    $('.navbar-global').toggleClass('mobile--menu');
    $('body').toggleClass('noscroll');
    $('.e--link').toggleClass('d-flex').toggleClass('d-none');
    $(this).removeClass('d-block').addClass('d-none')
    $('.menu-burger').toggleClass('d-none')
    $('.top-nav').addClass('d-none')
    $('.menu-burger').focus();
  });


  $('.close--dropdown').click(function () {
    $(this).parent().removeClass('show');
  });


  function openModal($modal) {

    var scrollBarWidth = window.innerWidth - document.body.offsetWidth;
    $('body')
      .css('margin-right', scrollBarWidth)
    $modal.show();
  };

  function closeModal($modal) {
    $('body').css('margin-right', '')
  };


  $('.close-alert').click(function () {
    $('.alert--message--container').hide();
    localStorage.setItem('ftAlertShowed', true);
  });

  $(document).ready(function () {


    if (localStorage.getItem("ftAlertShowed") === null) {
      $('.alert--message--container').show();
    }

    'use strict';

    var c, currentScrollTop = 0,
      navbar = $('.navbar-global');


    $(window).scroll(function () {



      var a = $(window).scrollTop();
      var b = navbar.height();

      currentScrollTop = a;

      if (c < currentScrollTop && a > b + b) {
        navbar.addClass("scrollUp");
        navbar.addClass("position-fixed");
      } else if (c > currentScrollTop && !(a <= b)) {
        navbar.removeClass("scrollUp");
      }
      c = currentScrollTop;
    });

  });

  function initTestimonialCarousel() {
    if ($(".carousel-testinomials").length > 0) {
    
      $('.carousel-testinomials').on('init', function (e, slick) {
        slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
          return buttonText.innerText = buttonText.innerText.replace('Go to slide', __('Allez au témoignage', 'fortytwo'));
        });
      });

      $('.carousel-testinomials').slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        centerMode: true,
        regionLabel: 'carrousel',
        loop: true,
        centerPadding: '20%',
      });


    }
  }



  window.addEventListener('resize', initTestimonialCarousel);

  if ($(".carousel-testinomials").length > 0) {

    $('.carousel-testinomials').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', __('Allez au témoignage', 'fortytwo'));
      });
    });


    $('.carousel-testinomials').slick({
      arrows: false,
      arrows: true,
      regionLabel: 'carrousel',
      dots: true,
      responsive: [{
          breakpoint: 3000,
          settings: {
            slidesToShow: 1,
            centerPadding: '20%',
            centerMode: true,
            loop: true,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            centerPadding: '20%',
            centerMode: true,
            loop: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      prevArrow: '<button class="previous-button is-control">' +
        '  <span class="fas fa-angle-left-dark" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive précédente</span>' +
        '</button>',
      nextArrow: '<button class="next-button is-control">' +
        '  <span class="fas fa-angle-right-dark" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive suivante</span>' +
        '</button>'
    });

  }


  if ($(".carousel-pure-images").length > 0) {
    $('.carousel-pure-images').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', __("Allez à l'image", 'fortytwo'));
      });
    });


    $('.carousel-pure-images').slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      regionLabel: 'carrousel',
      centerMode: true,
      loop: true,
      autoplay: true,
      centerPadding: '20%',
    });

  }



  if ($(".carousel-cards").length > 0) {

    $('.carousel-cards').on('init', function (e, slick) {
      slick.$pauseButton[0].childNodes[3].innerText = _x("Lecture", 'fortytwo');
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', _x("Allez à l'information", 'fortytwo'));
      });
    });

    $( document ).ready(function() {
    
    $('.carousel-cards').slick({
      dots: true,
      arrows: true,
      slidesToShow: 4,
      infinite: true,
      autoplay: true,
      regionLabel: 'carrousel',
      responsive: [{
          breakpoint: 3000,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      prevArrow: '<button class="previous-button is-control">' +
        '  <span class="fas fa-angle-left-white" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive précédente</span>' +
        '</button>',
      nextArrow: '<button class="next-button is-control">' +
        '  <span class="fas fa-angle-right-white" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive suivante</span>' +
        '</button>',
    });

    });


  }
  



  if ($(".carousel-text").length > 0) {


    $('.carousel-text').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', __("Allez à l'information", 'fortytwo'));
      });
    });


    $('.carousel-text').slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      regionLabel: 'carrousel',
      responsive: [{
          breakpoint: 2000,
          settings: {
            slidesToShow: 1,
            centerPadding: '60px',
            centerMode: true,
             loop: true,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            centerPadding: '60px',
            centerMode: true,
             loop: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            loop: false,
          }
        }
      ],
    });

  }





  if ($(".dark-carousel").length > 0) {

    $('.dark-carousel').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        if (document.documentElement.lang === 'fr') {
          return buttonText.innerText = buttonText.innerText.replace('Go to slide', "Allez au contenu");
        } else {
          return buttonText.innerText = buttonText.innerText.replace('Go to slide', "Go to content");
        }
      });
    });


    $('.dark-carousel').slick({
      dots: true,
      arrows: true,
      slidesToShow: 4,
      infinite: true,
      regionLabel: 'carrousel',
      responsive: [{
          breakpoint: 2000,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      prevArrow: '<button class="previous-button is-control">' +
        '  <span class="fas fa-angle-left-white" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive précédente</span>' +
        '</button>',
      nextArrow: '<button class="next-button is-control">' +
        '  <span class="fas fa-angle-right-white" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive suivante</span>' +
        '</button>'
    });

  }

  if ($(".carousel-image-left").length > 0) {


    $('.carousel-image-left').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', __("Allez à l'image", 'fortytwo'));
      });
    });



    $('.carousel-image-left').slick({
      dots: true,
      arrows: false,
      arrows: true,
      slidesToShow: 1,
      regionLabel: 'carrousel',
      infinite: true,
      autoplay: true,
      prevArrow: '<button class="previous-button is-control d-none d-md-block">' +
        '  <span class="fas fa-angle-left" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive précédente</span>' +
        '</button>',
      nextArrow: '<button class="next-button is-control d-none d-md-block">' +
        '  <span class="fas fa-angle-right" aria-hidden="true"></span>' +
        '  <span class="sr-only">Diapositive suivante</span>' +
        '</button>'
    });

  }



  if ($(".carousel-images-slider-left").length > 0) {

    $('.carousel-images-slider-left').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        return buttonText.innerText = buttonText.innerText.replace('Go to slide', __("Allez à l'image", 'fortytwo'));
      });
    });


    $('.carousel-images-slider-left').slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      regionLabel: 'carrousel',
      infinite: true,
      autoplay: true,
      responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

  }

  if ($(".stats-container").length > 0) {

    $('.stats-container').on('init', function (e, slick) {
      slick.$dots[0].querySelectorAll('button .slick-sr-only').forEach(function (buttonText) {
        buttonText.setAttribute("tabindex", "-1");
        // return buttonText.innerText = buttonText.innerText.replace('Go to slide', __("Allez à la statistique", 'fortytwo'));
        if (document.documentElement.lang === 'fr') {
          return buttonText.innerText = buttonText.innerText.replace('Go to slide', "Allez à la statistique");
        } else {
          return buttonText.innerText = buttonText.innerText.replace('Go to slide', "Go to stat");
        }
      });
    });


    $('.stats-container').slick({
      dots: true,
      arrows: false,
      slidesToShow: 4,
      regionLabel: 'carrousel',
      infinite: true,
      responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

  }



  if ($(".carousel").length > 0) {


    $('.carousel').slick({
      dots: true,
      arrows: false,
      slidesToShow: 4,
      regionLabel: 'carrousel',
      infinite: true,
      autoplay: true,
      responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });


  }
});